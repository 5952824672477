.hero-main {
  width: 100%;
  position: relative;
  clip-path: path('M0,0 H100% V85 Q50% 100%, 0 85 Z');
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  height: 50rem;
  overflow: hidden;
}

.hero-content {
  pointer-events: none;
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 10;
  height: 50rem;
}

.hero-title {
  text-align: center;
  color: #f5f5f5;
}

.hero-subtitle {
  color: #ffffff;
  width: 60%;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  letter-spacing: 0.05em;
}

.hero-gradient-text {
  font-size: 9rem !important;
  line-height: 9rem !important;
  font-weight: 900 !important;
  letter-spacing: 0.05em !important;
  background: rgba(163, 217, 185, 1) 51.5%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}



.hero-content button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  z-index: 1000;
  pointer-events: auto;
}

.hero-content button.learn-more {
  margin-top: 1rem;
  width: 15rem;
  height: auto;
}

.hero-content button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: var(--green-color-primary-dark);
  border-radius: 1.625rem;
}

.hero-content button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

.hero-content button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.hero-content button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

.hero-content button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: white;
  font-weight: 500;
  line-height: 1.6;
  font-size: medium;
  text-align: center;
  text-transform: uppercase;
  font-family: var(--paragraph-fonts);
}

.hero-content button:hover .circle {
  width: 100%;
}

.hero-content button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}

.hero-content button:hover .button-text {
  color: #fff !important;
}

/* 4K Desktop */
@media only screen and (min-width: 2560px) {

}

/* HD Desktop */
@media only screen and (min-width: 1920px) and (max-width: 2559px) {

}

/* Mini Desktop */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .hero-title {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }
  
  .hero-subtitle {
    width: 80% !important;
    font-size: 1.2rem !important;
    line-height: 1.7rem !important;
  }
  .hero-gradient-text {
    font-size: 6rem !important;
    line-height: 6rem !important;
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .hero-title {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
  
  .hero-subtitle {
    width: 80% !important;
    font-size: 1.2rem !important;
    line-height: 1.7rem !important;
  }
  .hero-gradient-text {
    font-size: 4rem !important;
    line-height: 4rem !important;
  }
}
