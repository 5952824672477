.services-hero-container {
  width: 100%;
  height: 40rem;
  padding: 5rem;
  background-color: var(--green-color-accent-dark);
}

.hero-content-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding-top: 5rem;
}

.service-hero-heading {
  color: white;
  width: 70%;
  text-align: center;
}

.services-hero-sec-icon {
  width: 1.75rem;
  height: 1.75rem;
  color: var(--green-color-primary);
  margin-right: 0.6rem;
}

.service-description {
  color: white;
  width: 70%;
  text-align: center;
}

.colored-name {
  color: var(--green-color-primary);
}

.services-custom-button {
  margin-top: 1rem;
  width: 10rem !important;
  height: 3rem !important;
  font-size: 1rem;
  font-weight: 500;
  font-family: var(--paragraph-fonts);
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  color: var(--white-colour);
  background-color: var(--green-color-primary);
  box-shadow: 0 0 0px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s ease-in-out;
}

.services-custom-button:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: var(--green-color-primary) !important;
  border: none !important;
  color: white !important;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .service-hero-heading {
    font-size: 2rem !important;
    line-height: 3rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .services-hero-container {
    padding: 2rem !important;
  }
  .service-hero-heading {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    width: 100% !important;
  }
  .service-description {
    width: 100% !important;
  }
}