.footer-container {
    width: 100%;
    height: 30rem;
    background-color: var(--green-color-accent-dark);
    position: relative;
    overflow-x: hidden;
}

.colored-text-misgenie{
    font-size: 16rem !important;
    line-height: 9.13rem;
    font-weight: 900 !important;
    letter-spacing: 0.05em !important;
    background: linear-gradient(to top, #1a4a45, #30928e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.footer-content {
    width: 100%;
    height: 90%;
    border-bottom: 1px solid white;
    display: flex;
    z-index: 10;
}

.footer-left-content {
    /* width: 40%; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    padding-left: 5rem;
    padding-right: 2rem;
    z-index: 10;
}

.footer-text {
    text-align: left;
    font-size: 1rem;
    line-height: 1.5rem;
    color: white;
    font-weight: 400;
    font-family: var(--paragraph-fonts);
    letter-spacing: 0.25px;
}

.footer-title {
    text-align: left;
    font-size: 1.1rem;
    line-height: 1.3rem;
    color: white;
    font-weight: 700;
    font-family: var(--heading-fonts);
    letter-spacing: 0.25px;
}

.footer-point {
    text-align: left;
    font-size: 0.9rem;
    line-height: 1.3rem;
    color: white;
    font-weight: 400;
    font-family: var(--paragraph-fonts);
    letter-spacing: 0.25px;
}

.footer-point:hover {
    color: var(--green-color-secondary) !important;
    cursor: pointer;
}

.media-links-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.footer-right-content {
    /* width: 60%; */
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-left: 2rem;
    z-index: 10;
}

.footer-right-col {
    /* width: 30%; */
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: left;
}

.footer-footer {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.5rem;
}

.footer-footer-text {
    text-align: center;
    font-size: 0.8rem;
    color: white;
    font-weight: 200;
    font-family: var(--paragraph-fonts);
    letter-spacing: 0.25px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .footer-left-content {
        /* width: 30% !important; */
        padding-left: 2rem !important;
        padding-right: 2rem;
    }

    .footer-right-content {
        /* width: 70% !important; */
    }

    .colored-text-misgenie{
        font-size: 7rem !important;
        line-height: 4rem !important;
    }
}
  
@media only screen and (max-width: 767px) {
    .footer-left-content {
        width: 100% !important;
        padding-left: 2rem !important;
        padding-right: 2rem;
    }

    .footer-right-content {
        width: 100% !important;
    }

    .colored-text-misgenie{
        font-size: 7rem !important;
        line-height: 4rem !important;
    }
    .footer-container {
        height: 60rem !important;
    }

    .footer-right-col{
        padding-left: 2rem !important;
    }
}