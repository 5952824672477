.modules-container {
  width: 100%;
  height: 50rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  align-items: center;
}

.modules-title {
  color: black;
  margin-bottom: 2rem;
  text-align: center;
}

.marquee-container {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 3rem;
  overflow: hidden;
  flex-direction: row;
  z-index: 10;
}

.marquee {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Marquee Card CSS */

.marquee-card {
  width: 100%;
  height: 100%;
  background: white;
  transition: 1s ease-in-out;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid lightgray;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 5rem;
  transform: box-shadow 0.5s ease-in-out;
}

.marquee-card:hover{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
}

.img-container {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
}

.img {
  width: 90%;
  height: 90%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -3rem;
}

.content-container {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.heading {
  font-weight: 800;
  font-family: var(--heading-fonts);
  letter-spacing: 0.4px;
  font-size: 1.7rem;
  line-height: 2rem;
  color: black;
  text-align: center;
}

.info {
  font-family: var(--paragraph-fonts);
  font-weight: 400;
  color: #6e6e70;
  text-align: center;
  font-size: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.marquee-card button {
  border-radius: 10px;
  border: none;
  font-weight: 400;
  font-size: 1.1rem;
  height: 3rem;
  padding: 0rem 1.5rem;
  font-family: var(--paragraph-fonts);
  background: var(--green-color-primary-light);
  color: white;
  transition: 0.4s ease-in-out;
  margin-bottom: -1.5rem;
}

.marquee-card button:hover {
  background: var(--green-color-accent-dark);
  color: white;
  cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .marquee {
    width: 100% !important;
    height: 100% !important;
  }
  .modules-title {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }
  .img-container {
    height: 30% !important;
  }

  .heading {
    font-size: 1.2rem !important;
    line-height: 1.7rem !important;
  }
  .info {
    font-size: 0.9rem !important;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .modules-container {
   display: none !important;
  }
}