.services-form-container-col {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35rem;
}

.services-form-container {
  width: 80%;
  height: auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.services-form-input-label {
  color: black;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-family: var(--paragraph-fonts);
  font-weight: 400;
}

.services-form-items-container {
  width: 80%;
}

.services-form-heading {
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0.25px;
  color: black;
  font-family: var(--heading-fonts);
  font-weight: 700;
}

.services-form-description {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.25px;
  color: black;
  font-family: var(--paragraph-fonts);
  font-weight: 400;
}

.services-form-description-mail {
  font-size: 1.2rem;
  color: #30928e;
  font-family: var(--paragraph-fonts);
  font-weight: 500;
  margin-left: 0.5rem;
  cursor: pointer;
}

.services-form-description-mail:hover {
  color: black;
}

.services-form-inputs-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.services-genie-support-position {
  position: absolute;
  right: 50px;
}

.services-attributes-cards {
  position: relative;
}

.services-custom-button {
    margin-top: 1rem;
    width: 10rem !important;
    height: 3rem !important;
    font-size: 1rem;
    font-weight: 500;
    font-family: var(--paragraph-fonts);
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    color: var(--white-colour);
    background-color: #50b0a2;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .services-custom-button:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #50b0a2 !important;
    border: none !important;
    color: white !important;
  }

  @media screen and (max-width: 767px) {
    .services-form-container {
      padding: 1rem !important;
    }
    
    .services-form-container-col {
      height: 55rem !important;
    }
    .services-form-heading {
      font-size: 1.5rem !important;
      line-height: 2rem !important;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .services-form-container-col {
      height: 55rem !important;
    }

  }