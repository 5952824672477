.services-cards-container {
  width: 100%;
  height: 65rem;
  background-color: white;
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cards-section{
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.services-card-horizontal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, .125);
  padding: 2rem;
  justify-content: center;
  align-items: center;
  transform: box-shadow 0.5s ease-in-out;
}

.services-card-horizontal:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.card-section-heading-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.service-card-horizontal-content{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card-section-heading {
  font-size: 3rem;
  line-height: 4rem;
  color: black;
  font-weight: 600;
  letter-spacing: -0.05em;
  font-family: var(--heading-fonts);
  text-align: center;
  width: 60%;
}

.colored-description {
  font-size: 1rem;
  line-height: 2rem;
  color: var(--green-color-accent-dark);
  letter-spacing: 0.25px;
  font-weight: 600;
  font-family: var(--paragraph-fonts);
  text-align: center;
}

.colored-heading {
  color: var(--green-color-primary);
}

.card-section-description {
  font-size: 1.5rem;
  line-height: 2rem;
  color: white;
  font-weight: 400;
  letter-spacing: 0.25px;
  font-family: var(--paragraph-fonts);
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 30rem;
}

.services-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, .125);
  padding: 2rem;
  height: 25rem;
  width: 100%;
  transform: box-shadow 0.5s ease-in-out;
}

.services-card:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.service-card-content{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.service-card-image {

  display: flex;
  justify-content: left;
  align-items: center;
}

.card-heading {
  font-size: 1.5rem;
  line-height: 2rem;
  color: black;
  font-weight: 600;
  font-family: var(--heading-fonts);
  letter-spacing: 0.25px;
}

.card-description {
  font-weight: 1rem;
  line-height: 1.5rem;
  color: gray;
  font-family: var(--paragraph-fonts);
  font-weight: 400;
  text-align: left;
}

.horizontal-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.service-image{
  width: 130px;
  height: auto;
}

/* @media screen and (max-width: 1024px) {
  .services-cards-container {
    height: 70rem !important;
  }

  .services-card{
    width: 100% !important;
    height: 100% !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
  }
  
} */

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .services-cards-container {
    height: 80rem !important;  
  }
  .services-card{
    width: 100% !important;
    height: 100% !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .service-card-image {
    width: 40% !important;
  }
  .card-section-heading {
    font-size: 2rem !important;
    line-height: 3rem !important;
  }
  .card-heading {
    font-size: 1.2rem !important;
    line-height: 1.7rem !important;
  }
}

@media only screen and (max-width: 450px) and (min-width: 0px) {
  .services-cards-container {
    height: 110rem !important;  
  }
  .services-card{
    width: 100% !important;
    height: 100% !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .service-card-image {
    display: none !important;
  }
  .card-section-heading {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
  .card-heading {
    font-size: 1.2rem !important;
    line-height: 1.7rem !important;
  }
  .services-card-horizontal {
    flex-direction: column !important;
  }
  .service-image{
    width: 70px !important;
    height: auto;
  }
}
@media only screen and (max-width: 767px) and (min-width: 451px) {
  .services-cards-container {
    height: 90rem !important;  
  }
  .services-card{
    width: 100% !important;
    height: 100% !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .service-card-image {
    display: none !important;
  }
  .card-section-heading {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
  .card-heading {
    font-size: 1.2rem !important;
    line-height: 1.7rem !important;
  }
  .services-card-horizontal {
    flex-direction: column !important;
  }
  .service-image{
    width: 70px !important;
    height: auto;
  }
}