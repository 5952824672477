.notfound-tagline {
  font-family: var(--storefront-heading-font-family);
  font-size: 2rem;
  margin-top: 0.9rem;
  font-weight: 800;
  color: var(--black-color);
  text-align: start;
}
.notfount-content {
  font-family: var(--storefront-paragraph-font-family);
  font-size: 1rem;
  text-align: start;
  font-weight: 500;
  color: var(--black-color);
}

.notfound-genie-image {
  max-width: 100%;
  max-height: 80vh;
  position: absolute;
  bottom: 0px;
  right: 0px;
  -webkit-animation: pulsate-bck 5s cubic-bezier(0.6, -0.28, 0.735, 0.045)
    infinite both;
  animation: pulsate-bck 5s cubic-bezier(0.6, -0.28, 0.735, 0.045) infinite both;
}

@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.notfound-main-container {
  min-height: 100vh;
  margin-left: 15em;
}

.notfound-home-btn {
  margin-top: 0.9rem;
  background: linear-gradient(84.99deg, #449afa 0%, #1d63ff 100%);
  font-family: var(--storefront-heading-font-family) !important;
  box-shadow: var(--dashboard-common-blue-shadow) !important;
  color: white;
  height: 45px !important;
  width: 220px;
}
