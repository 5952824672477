.genie-img {
  width: 7rem;
  height: 7rem;
}

.history-main-container {
  height: auto;
  max-height: 30rem;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.genie-drawer-container {
  overflow-x: hidden;
  overflow-y: auto;
}

.genie-btn {
  color: white !important;
  background: var(--green-color-primary) !important;
  font-family: var(--storefront-heading-font-family) !important;
  border: none !important;
  font-weight: 500;
  height: 32px !important;
  width: 15em;
}

.genie-btn:hover {
  color: white !important;
  background: var(--green-color-primary) !important;
  font-family: var(--storefront-heading-font-family) !important;
}

.genie-btn-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.explore-btn {
  max-width: 11em !important;
  border: 1px solid var(--green-color-primary) !important;
  color: var(--green-color-primary-dark) !important;
  font-family: var(--storefront-heading-font-family) !important;
}

.explore-btn:hover {
  border: 1px solid var(--green-color-primary) !important;
}

.history-main-container strong,
.genie-question {
  color: var(--green-color-primary-dark) !important;
  font-family: var(--storefront-heading-font-family) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.history-main-container p,
.genie-answer {
  font-family: var(--storefront-paragraph-font-family) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: var(--black-color);
}

.genie-answer {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}