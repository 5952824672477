@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
}

:root {
  --color-transparent: transparent;
  --primary-color: #ff6800;
  --secondary-color: #449afa;
  --secondary-color-light-shade: #d9ebff99;
  --black-color: #343436;
  --store-front-primary-light-color: #ffe9db;
  /* Green Theme colors */
  --green-color-primary-dark: #011918;
  --green-color-primary: #50b0a2;
  --green-color-primary-light: #30928e;
  --green-color-secondary: #6cc8b1;
  --green-color-accent-dark: #1a4a45;
  --green-color-accent-light: #c0fff6;
  --green-color-secondary-light-alpha: #6cc8b11a;
  --border-color-light: #e5e7eb;
  --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --accent-color-1: #1677ff;
  --accent-color-2: #1e90ff;
  --neutral-color: #000080;
  /*Heading Text font */
  --gray-colour: #60606c;
  /*paragraph Text font */
  --lightgray-colour: #d3d3d3;
  /*paragraph Text font */
  --white-colour: #ffffff;
  --background-color: #f5f5f5;
  --dashboard-common-blue-shadow: 0 8px 32px 0 rgb(31 38 135 / 13%);
  --dashboard-common-orange-shadow: var(--store-front-primary-light-color) 0px
    8px 24px;

  /* Fonts */
  --storefront-heading-font-family: "Montserrat", sans-serif;
  --storefront-paragraph-font-family: "Lato", sans-serif;
  --custom-font: "Sora";
  --style-font: "Poppins";
  --poppins-font: "Lato", sans-serif;
  --lora-font: "Lora";

  /* landing-page-fonts */
  /* Note: dont change font variable names. Strictly Prohibited! */
  --paragraph-fonts: "Lato", sans-serif;
  --heading-fonts: "Montserrat", sans-serif;

  /* Shadow */
  --box-shadow: rgba(6, 6, 188, 0.05) 0px 1px 0px,
    rgba(28, 28, 97, 0.1) 0px 0px 8px;
  --portal-heading-font: "GeistFonts";
}

/*Form Labels*/
label {
  font-family: var(--storefront-paragraph-font-family);
  font-weight: bold;
}

.ant-menu-dark .ant-menu-item-selected {
  background-color: #ff4500;
  /*Orange Red*/
  color: white;
}

.ant-steps-item-active
  > div.ant-steps-item-container
  > div.ant-steps-item-icon {
  background: linear-gradient(84.99deg, #449afa 0%, #1d63ff 100%) !important;
  color: var(--white-colour);
}

.ant-steps-item-wait {
  background-color: var(--white-colour);
  color: var(--black-color);
}

/* Button */

/* Button text color and font styling */

.storefront-green-button {
  width: 10rem !important;
  height: 3rem !important;
  font-size: 1rem;
  font-weight: 500;
  font-family: var(--paragraph-fonts);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: var(--white-colour);
  background-color: var(--green-color-primary);
  box-shadow: 0 0 0px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s ease-in-out;
  border: none;
}

.storefront-green-button:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: var(--green-color-primary) !important;
  border: none !important;
  color: white !important;
}

.storefront-default-btn {
  color: #111927 !important;
  width: 10rem !important;
  height: 3rem !important;
  background-color: var(--white-colour) !important;
  font-family: var(--portal-heading-font) !important;
  border-color: #e5e7eb !important;
}

.storefront-default-btn:hover {
  border-color: #e5e7eb !important;
}

.custom-primary-btn {
  color: white !important;
  background: var(--green-color-primary-light) !important;
  font-family: var(--storefront-heading-font-family) !important;
  border: none !important;
  font-weight: 500;
  height: 32px !important;
  max-width: 15em;
  min-width: 7em;
}

.custom-primary-btn:not([disabled]):hover {
  color: white !important;
  background: var(--green-color-primary-light) !important;
  font-family: var(--storefront-heading-font-family) !important;
  box-shadow: var(--dashboard-common-blue-shadow) !important;
}

.custom-text {
  font-family: var(--storefront-paragraph-font-family) !important;
}

.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: black !important;
}

.glass-effect {
  backdrop-filter: blur(4px) saturate(114%) !important;
  -webkit-backdrop-filter: blur(4px) saturate(114%) !important;
  background-color: rgba(255, 255, 255, 0.78) !important;
  box-shadow: var(--dashboard-common-blue-shadow) !important;
}

body {
  --h: 212deg;
  --l: 43%;
  --brandColor: hsl(var(--h), 71%, var(--l));
  margin: 0;
  background-color: rgb(255, 255, 255);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--gray-colour) var(--color-transparent);
  /* scroll-behavior: smooth; */
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--gray-colour);
  border-radius: 20px;
  border: 3px solid var(--white-colour);
}

.custom-scrollbar-container {
  overflow: auto !important;
}

.custom-scrollbar-container .content {
  overflow: auto;
  max-height: 100vh !important;
}

.custom-scrollbar-container .side-nav-content {
  overflow: auto;
  max-height: 81.5vh !important;
}

.side-nav-content .ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: none !important;
}

.star-color-required-field {
  color: red;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: transparent !important;
}

.suspense-blur-screen {
  backdrop-filter: blur(10px);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  background-color: #f3f2f2;
}

.policy-descriptions
  > div.ant-descriptions-view
  > table
  > tbody
  > tr.ant-descriptions-row {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(181, 181, 181) !important;
  box-shadow: rgb(181, 181, 181) !important;
}

.policy-descriptions
  > div.ant-descriptions-view
  > table
  > tbody
  > tr.ant-descriptions-row
  > td {
  background-color: rgb(254, 254, 255) !important;
  box-shadow: rgb(254, 254, 255) !important;
}

.unclickable-div {
  pointer-events: none;
  cursor: default;
  border-bottom: 1px solid rgb(130, 130, 130);
}

.set-Button-position-to-right {
  display: flex;
  justify-content: flex-end !important;
}

.ant-modal-wrap {
  overflow: hidden !important;
}

.policy-descriptions
  > div.ant-descriptions-view
  > table
  > tbody
  > tr.ant-descriptions-row {
  color: #ffff !important;
  background-color: #fafafa !important;
  box-shadow: #fafafa !important;
  border-radius: 0.2rem !important;
}

.policy-descriptions
  > div.ant-descriptions-view
  > table
  > tbody
  > tr.ant-descriptions-row
  > td {
  color: #ffff !important;
  background-color: rgb(254, 254, 255) !important;
  box-shadow: rgb(254, 254, 255) !important;
}

.custom-primary-btn-width {
  min-width: 9rem !important;
  font-family: var(--storefront-heading-font-family) !important;
}

.demo-btn {
  height: 3rem !important;
  border: none !important;
}

/* Modal and Tables Customization classes in admin and HR Portal */

.ant-steps-item-active
  > div.ant-steps-item-container
  > div.ant-steps-item-icon {
  background: linear-gradient(84.99deg, #449afa 0%, #1d63ff 100%);
  color: var(--white-colour);
}

.ant-steps-item-wait,
.ant-steps-item-wait {
  background-color: var(--white-colour);
  color: var(--black-color);
}

.modal-content {
  border: none !important;
}

.ant-form-item-label > label {
  font-family: var(--storefront-heading-font-family);
  font-weight: 700;
  font-size: 1rem;
}

th.ant-table-cell {
  font-family: var(--storefront-heading-font-family);
  font-weight: 700;
  font-size: 1rem;
}

td.ant-table-cell {
  font-family: var(--storefront-paragraph-font-family);
}

.ant-tabs-tabpane > div > h5 {
  font-family: var(--storefront-heading-font-family) !important;
  font-weight: 700 !important;
  font-size: 1.5rem !important;
}

.admin-hr-potal-table-headings {
  font-family: var(--storefront-heading-font-family);
  font-size: 2rem;
}

/* Tour CSS */

.custom-tour-heading {
  font-size: 1.2rem;
  font-weight: bold;
  font-family: var(--storefront-heading-font-family);
}

.custom-tour-description {
  font-size: 0.9rem;
  font-weight: normal;
  font-family: var(--storefront-paragraph-font-family);
}

.ant-picker,
.ant-input-affix-wrapper,
.ant-select-selector,
select,
.ant-form-item-control-input-content > input.ant-input {
  min-height: 40px !important;
  font-family: var(--style-font);
  box-shadow: none;
}

/* select::placeholder,
input::placeholder {
  color: #6c737f;
  padding-left: 0.3rem;
  font-size: 1.3rem;
  font-family: "Poppins";
} */

.ant-form-item .ant-form-item-explain-error {
  margin-top: 6px !important;
}

.ant-select .ant-select-arrow {
  margin-top: 0px !important;
}

.view-modal-heading {
  margin-top: 2rem;
  text-align: -moz-left;
  color: white;
  background: linear-gradient(84.99deg, #449afa 0%, #1d63ff 100%) !important;
  font-family: var(--storefront-heading-font-family);
  padding: 12px;
  border-radius: 5px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  box-shadow: var(--secondary-color-light-shade) 0px 4px 6px;
}

.table-main-container {
  display: flex;
  justify-content: center;
  border: 1px solid #e5e7eb;
  border-radius: 20px;
  width: 100%;
  min-height: 70vh;
  border-radius: 10px !important;
  margin-bottom: 4em;
  padding-bottom: 2em;
  margin-top: 2em;
}

.table-main-container > div {
  width: 100%;
  padding: 1em;
}

/* Genie Tag CSS */

.genie-tag-style-container {
  padding-top: 1rem;
}

.genie-tag-style {
  width: auto;
  height: auto;
  min-height: 3rem;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1rem;
  font-family: var(--storefront-paragraph-font-family);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--green-color-secondary-light-alpha);
  overflow-x: hidden;
}

.genie-tag-style:hover {
  transition: 0.1s;
  border: 0.1px solid var(--green-color-accent-dark);

  box-shadow: var(--box-shadow);
  background-color: white;
}

.genie-tag-style-icon {
  width: 40px;
  height: auto;
}

.ant-dropdown-menu {
  box-shadow: none !important;
  border: 1px solid #e5e7eb;
}

.global-create-steps-modal
  > div.ant-steps-item-wait
  > div.ant-steps-item-container {
  clip-path: polygon(
    92% 0%,
    100% 50%,
    92% 100%,
    0% 100%,
    8% 50%,
    0% 0%
  ) !important;

  padding: 0.5rem 2rem !important;
  width: 100% !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d2d6db !important;
}

.global-create-steps-modal
  > div.ant-steps-item-wait
  > div.ant-steps-item-container::after {
  content: "";
  position: absolute;
  clip-path: polygon(
    92% 0%,
    100% 50%,
    92% 100%,
    0% 100%,
    8% 50%,
    0% 0%
  ) !important;
  width: 99% !important;
  background-color: white !important;
  min-height: 4.3rem !important;
}

/* not first child finish and active class for steps */

.global-create-steps-modal
  > div.ant-steps-item:not(:first-child).ant-steps-item-finish
  > div.ant-steps-item-container {
  background-color: var(--green-color-primary-light) !important;
  /* border color of filled for remaining step. */
  clip-path: polygon(
    92% 0%,
    100% 50%,
    92% 100%,
    0% 100%,
    8% 50%,
    0% 0%
  ) !important;

  padding: 0.5rem 2rem !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.global-create-steps-modal
  > div.ant-steps-item:not(:first-child).ant-steps-item-active
  > div.ant-steps-item-container {
  clip-path: polygon(
    92% 0%,
    100% 50%,
    92% 100%,
    0% 100%,
    8% 50%,
    0% 0%
  ) !important;

  padding: 0.5rem 2rem !important;
  width: 100% !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green-color-primary-light) !important;
  /* border color of other remaining active step. */
}

.global-create-steps-modal
  > div.ant-steps-item:not(:first-child).ant-steps-item-active
  > div.ant-steps-item-container::after {
  content: "";
  position: absolute;
  clip-path: polygon(
    92% 0%,
    100% 50%,
    92% 100%,
    0% 100%,
    8% 50%,
    0% 0%
  ) !important;
  width: 99% !important;
  background-color: white !important;
  min-height: 4.3rem !important;
}

/* first child finish and active class for steps */

.global-create-steps-modal
  > div.ant-steps-item:first-child.ant-steps-item-finish
  > div.ant-steps-item-container {
  background-color: var(--green-color-primary-light) !important;
  /* border color of finished filled step. */
  clip-path: polygon(0% 0%, 92% 0%, 100% 50%, 92% 100%, 0% 100%) !important;

  padding: 0.5rem 2rem !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.global-create-steps-modal
  > div.ant-steps-item:first-child.ant-steps-item-active
  > div.ant-steps-item-container {
  clip-path: polygon(0% 0%, 92% 0%, 100% 50%, 92% 100%, 0% 100%) !important;

  padding: 0.5rem 2rem !important;
  width: 100% !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green-color-primary-light) !important;
  /* border color of first step. */
}

.global-create-steps-modal
  > div.ant-steps-item:first-child.ant-steps-item-active
  > div.ant-steps-item-container::after {
  content: "";
  position: absolute;
  clip-path: polygon(0% 0%, 92% 0%, 100% 50%, 92% 100%, 0% 100%) !important;

  width: 99% !important;
  background-color: white !important;
  min-height: 4.3rem !important;
}

.global-create-steps-modal
  > div.ant-steps-item-finish
  > div.ant-steps-item-container
  > div.ant-steps-item-icon {
  display: none !important;
}

.global-create-steps-modal
  > div.ant-steps-item-active
  > div.ant-steps-item-container
  > div.ant-steps-item-icon {
  display: none !important;
}

.global-create-steps-modal
  > div.ant-steps-item-active
  > div.ant-steps-item-container
  > div.ant-steps-item-icon {
  display: none !important;
}

.global-create-steps-modal
  > div.ant-steps-item-active
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-title {
  color: var(--green-color-primary-light) !important;
  /* border color of active step title. */
  z-index: 1 !important;
}

.global-create-steps-modal
  > div.ant-steps-item-active
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-title::after {
  display: none !important;
}

.global-create-steps-modal
  > div.ant-steps-item-wait
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-title::after {
  display: none !important;
}

.global-create-steps-modal
  > div.ant-steps-item-finish
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-title::after {
  display: none !important;
}

.global-create-steps-modal
  > div.ant-steps-item-active
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-description {
  color: var(--green-color-primary-light) !important;
  /* border color of active step description. */
  z-index: 1 !important;
  position: relative;
}

.global-create-steps-modal
  > div.ant-steps-item-finish
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-title {
  color: white !important;
  /* border color of fininshed step title. */
}

.global-create-steps-modal
  > div.ant-steps-item-finish
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-description {
  color: white !important;
  /* border color of fininshed step desc. */
}

.global-create-steps-modal
  > div.ant-steps-item-wait
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-title {
  color: #384250 !important;
  /* border color of in-active step title. */
  z-index: 1 !important;
}

.global-create-steps-modal
  > div.ant-steps-item-wait
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-description {
  color: #6c737f !important;
  /* border color of inactive step desc. */
  z-index: 1 !important;
  position: relative;
}

.global-create-steps-modal {
  margin-bottom: 2rem !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

.global-create-steps-inner-container {
  flex: 1 !important;
}

.global-default-btn {
  color: #111927 !important;
  background-color: var(--white-colour) !important;
  font-family: var(--portal-heading-font) !important;
  border-color: #e5e7eb !important;
}

.global-default-btn:hover {
  border-color: #e5e7eb !important;
}

.global-primary-btn {
  background-color: var(--green-color-primary-light) !important;
  color: white !important;
  border-color: var(--green-color-primary-light) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--portal-heading-font);
}

.global-primary-btn:hover {
  background-color: var(--green-color-primary-light) !important;
  color: white !important;
  border-color: var(--green-color-primary-light) !important;
}

.global-btn-width {
  min-width: 9rem !important;
}

.body-content-card-container {
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  margin-top: 3rem;
}

.body-content-card-inner-container > .ant-card-body {
  padding: 0rem !important;
}

.global-kpi-list-header {
  margin-top: 1.5rem;
}

.global-card-inner-container {
  background-color: var(--white-colour);
  margin-top: 10px;
  max-height: 35rem;
  height: 95%;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  padding: 1rem 1rem 0 1rem;
}

.policy-modal-container-spacing {
  margin-top: 0px !important;
  padding: 0px !important;
}

.global-modal-bottom-row {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid lightgrey;
  width: 100%;
  padding-top: 1rem;
}

.global-modal-main-container {
  max-height: 40rem !important;
}

.global-modal-title {
  border-bottom: 1px solid lightgrey;
  padding-bottom: 1rem;
  width: 98% !important;
  padding-left: 0rem !important;
  display: block !important;
}

.global-row-content-right {
  display: flex;
  justify-content: right !important;
}

.globel-upload-button > div > div.ant-upload-select {
  border-color: var(--green-color-primary-light) !important;
  color: var(--green-color-primary-light) !important;
}

.react-international-phone-input {
  min-width: 90% !important;
  height: 2.4rem !important;
}

.react-international-phone-country-selector > button {
  height: 2.4rem !important;
}

.dasboard-cards {
  font-family: var(--storefront-heading-font-family);
  border: 1px solid #e5e7eb;
  border-radius: 20px;
  box-shadow: none !important;
}

.dasboard-cards-for-requests {
  margin-top: 2em !important;
  font-family: var(--storefront-heading-font-family);
  /* border: 1px solid #e5e7eb;
  border-radius: 20px; */
  box-shadow: none !important;
}

.global-request-list-top-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem orem;
}

.global-request-container-styling {
  border: 1px solid #e5e7eb;
  border-radius: 20px;
}

.globel-justify-right {
  display: flex;
  justify-content: right;
  margin-right: 0.5rem;
}

/* landing page glowing text */

.shadow-text {
  color: var(--green-color-primary);
  font-weight: 700;
  text-shadow: 10px 3px 13px #c3fbed;
}
.shadow-icon {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9fafb;
}

.storefront-hero-heading {
  font-size: 42px;
  line-height: 50px;
  font-weight: 700;
  letter-spacing: -0.05em;
  font-family: var(--heading-fonts);
}

.storefront-description {
  font-size: 19px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0.25px;
  font-family: var(--paragraph-fonts);
}

.antd-tab-custamization
  > div.ant-tabs-nav
  > div.ant-tabs-nav-wrap
  > div.ant-tabs-nav-list
  > div.ant-tabs-tab {
  margin: 0px !important;
  padding: 5px !important;
  border-right: 1px solid;
  border-color: var(--green-color-primary-light) !important;
  border-radius: 0px !important;
}

.antd-tab-custamization
  > div.ant-tabs-nav
  > div.ant-tabs-nav-wrap
  > div.ant-tabs-nav-list
  > div.ant-tabs-tab-active {
  margin: 0px !important;
  border-bottom: none !important;
  border-bottom-color: white !important;
  border-color: var(--green-color-primary-light) !important;
  background-color: var(--green-color-secondary-light-alpha);
}

.ant-tabs-ink-bar {
  background: none !important;
}

.antd-tab-custamization > div.ant-tabs-nav {
  margin: 0px !important;
  border: 1px solid !important;
  border-radius: 5px !important;
  border-color: var(--green-color-primary-light) !important;
}

.tab-header-button-clicked {
  font-size: 1rem;
  font-family: var(--portal-heading-font);
  font-weight: 400;
  color: var(--green-color-primary-light) !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0.5rem;
  border-color: var(--green-color-primary-light) !important;
}

.tab-header-button-clicked:hover {
  background-color: none !important;
}

.tab-header-button-default {
  border: none !important;
  font-size: 1rem;
  color: var(--black-color) !important;
  font-family: var(--storefront-heading-font-family) !important;
  box-shadow: none !important;
  padding: 0.5rem;
}

.tab-header-button-default:hover {
  border-color: none !important;
  color: black !important;
  background-color: transparent !important;
}

/* PopConfirm CSS */

.ant-popconfirm
  > .ant-popover-content
  > .ant-popover-inner
  > .ant-popover-inner-content
  > .ant-popconfirm-inner-content
  > .ant-popconfirm-buttons
  > .ant-btn-primary {
  background-color: var(--green-color-primary-light);
}

.ant-popconfirm
  > .ant-popover-content
  > .ant-popover-inner
  > .ant-popover-inner-content
  > .ant-popconfirm-inner-content
  > .ant-popconfirm-message
  > .ant-popconfirm-message-icon
  > .anticon-exclamation-circle {
    color: var(--green-color-accent-light);
  }
