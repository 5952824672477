.reviews-container {
  background-color: white;
  width: 100%;
  height: 17rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: top;
  align-items: center;
}

.reviews-heading {
  color: black;
  z-index: 10;
}

.reviews-carousel-container {
  height: 100%;
  width: 80%;
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-card {
  width: 150px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  background-color: white;
  border-radius: 10px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30%;
}

.slider-content-container {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.slider-info {
  font-family: var(--paragraph-fonts);
  font-weight: 400;
  color: #6e6e70;
  text-align: center;
  font-size: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
.reviews-heading {
    font-size: 2rem !important;
    line-height: 2rem !important;
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
.reviews-heading {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    margin-top: 5rem !important;
  }
}