.platform-cards-container {
  width: 100%;
  height: auto;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.platform-cards-title {
  font-size: 3rem;
  line-height: 4rem;
  color: black;
  font-weight: 600;
  letter-spacing: -0.05em;
  margin-bottom: 2rem;
  font-family: var(--heading-fonts);
  text-align: center;
}

.platform-cards-cards-container {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  gap: 2rem;
}

.platform-cards {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, .125);
  transform: scale(1);
  transition: transform 0.4s ease-in-out;
  transition: box-shadow 0.4s ease-in-out;
  padding: 2rem 0rem;
}

.platform-cards:hover {
  transform: scale(1.03);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.platform-cards-icon-container {
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.platform-cards-text-content {
  width: 100%;
  height: 60%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.platform-cards-cards-title {
  font-size: 1.5rem;
  line-height: 2rem;
  color: black;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 1rem;
  font-family: var(--heading-fonts);
  text-align: center;
}

.platform-cards-description {
  font-size: 1rem;
  line-height: 1.5rem;
  color: gray;
  font-weight: 400;
  letter-spacing: 0.25px;
  font-family: var(--paragraph-fonts);
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .platform-cards-title {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }

  .platform-cards-cards-title{
    font-size: 1.2rem !important;
    line-height: 1.5rem !important;
  }
  
  .platform-cards-description {
    font-size: 0.9rem !important;
    line-height: 1.2rem !important;
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .platform-cards-title {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }

  .platform-cards-cards-title{
    font-size: 1.2rem !important;
    line-height: 1.5rem !important;
  }
  
  .platform-cards-description {
    font-size: 0.9rem !important;
    line-height: 1.2rem !important;
  }
}