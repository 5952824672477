.intro-container {
  width: 100%;
  height: auto;
  background-color: var(--green-color-secondary-light-alpha);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 3rem;
  justify-content: center;
  align-items: center;
}

.intro-title {
  color: black;
  margin-bottom: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.tabs-container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  height: 3rem;
  margin-bottom: 2rem;
  justify-content: center;
  background-color: transparent;
  position: relative;
}

.tab-item {
  border-radius: 9999px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  font-family: var(--storefront-paragraph-font-family);
  color: var(--green-color-primary-dark);
  width: 8rem;
  border: none;
  outline: none;
  transition: opacity 0.2s ease-in-out;
  position: relative;
  background-color: transparent;
}

.tab-item.active {
  color: white;
}

.tab-item:focus-visible {
  outline: 1px solid var(--green-color-primary);
}

.button-pill {
  position: absolute;
  inset: 0;
  border-radius: 10px;
  background-color: var(--green-color-primary);
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0);
}

.button-pill:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.button-text {
  position: relative;
  z-index: 10;
}

.tab-content {
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin-top: -3rem;
  position: relative;
}

.tab-img {
  width: auto;
  height: 100%;
  object-fit: contain;
  margin-top: 0rem;
  z-index: 10;
  position: relative;
  border-radius: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .intro-title {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .intro-container {
    display: none;
  }
}
