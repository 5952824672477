.bg-content-container {
  width: 100%;
  height: 32rem;
  padding: 3rem 0rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  margin-top: 3rem;
}

.bg-content-div {
  width: 70%;
  height: 100%;
  background-color: var(--green-color-secondary-light-alpha);
  border-radius: 10px;
  padding: 3rem;
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.bg-content-left-div {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, var(--green-color-accent-dark), var(--green-color-primary-light));
  padding: 1rem;
  border-radius: 10px;
  overflow: hidden;
}

.bg-content-right-div {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.bg-content-heading {
  font-size: 2rem !important;
  letter-spacing: 0.5px;
  font-family: var(--heading-fonts);
  font-weight: 700;
  line-height: 2rem !important;
}

.bg-content-description {
  color: #7b7b7b;
  font-size: 1rem !important;
  letter-spacing: 0.25px;
  font-family: var(--paragraph-fonts);
  font-weight: 400;
  line-height: 1.5rem;
}

.platform-custom-button {
  margin-top: 1rem;
  width: 10rem !important;
  height: 3rem !important;
  font-size: 1rem;
  font-weight: 500;
  font-family: var(--paragraph-fonts);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: var(--white-colour);
  background-color: var(--green-color-primary);
  box-shadow: 0 0 0px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s ease-in-out;
  border: none !important;
}

.platform-custom-button:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: var(--green-color-primary) !important;
  border: none !important;
  color: white !important;
}

.bg-content-img{
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.bg-content-img:hover {
  transform: scale(1.03);
  cursor: auto;
}

@media (min-width: 0px) and (max-width: 991px) {
  .bg-content-container {
    height: 40rem !important;
  }

  .bg-content-left-div {
    display: none !important;
  }

  .bg-content-right-div {
    width: 100% !important;
  }

  .bg-content-heading {
    font-size: 1.5rem !important;
    line-height: 1.5rem !important;
  }

  .bg-content-div {
    width: 80% !important;
    padding: 1rem !important;
    gap: 1rem !important;
  }
}

