.storefront-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 100px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(10px);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  z-index: 100;
}
.storefront-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  transition: 0.5s;
}
.storefront-header:hover::before {
  left: 100%;
}

.nav-logo-image {
  width: 10rem;
  height: auto;
}

.storefront-logo {
  color: #fff;
  font-size: 25px;
  text-decoration: none;
  font-weight: 600;
  cursor: default;
}
.storefront-navbar a {
  color: #fff;
  font-size: 18px;
  font-family: var(--paragraph-fonts);
  text-decoration: none;
  margin-left: 35px;
  transition: 0.3s;
}
.storefront-navbar a:hover {
  color: var(--green-color-primary);
}
#menu-icon {
  font-size: 36px;
  color: #fff;
  display: none;
}

.nav__cta {
  background: var(--green-color-primary);
  color: white;
  border: none;
  border-radius: 10px;
  font-family: var(--paragraph-fonts);
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s ease-in-out;
  padding: 0.75rem 1.5rem;
}

.nav__cta:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.storefront-navbar .nav-item--scrolled {
  color: black !important;
  font-size: 18px;
  font-family: var(--paragraph-fonts);
  text-decoration: none;
  margin-left: 35px;
  transition: 0.3s;
}

/* BREAKPOINTS */
@media (max-width: 992px) {
  .storefront-header {
    padding: 1.25rem 4%;
  }
}
@media (max-width: 768px) {
  #menu-icon {
    display: block;
  }
  .storefront-navbar {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 0.5rem 4%;
    display: none;
  }
  .storefront-navbar.active {
    display: block;
  }
  .storefront-navbar a {
    display: block;
    margin: 1.5rem 0;
  }
  .storefront-nav-bg {
    position: absolute;
    top: 79px;
    left: 0;
    width: 100%;
    height: 295px;
    background: rgba(255, 255, 255, 0.1);
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    z-index: 99;
    display: none;
  }
  .storefront-nav-bg.active {
    display: block;
  }
}
