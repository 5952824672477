.about-us-hero-container {
    width: 100%;
    height: 70vh;
    background-color: #1a4a45;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
  }
  
  .about-us-hero-icon{
    width: 20%;
    height: auto;
    position: absolute;
    top: 23%;
    left: 15%;
  }
  
  .about-us-hero-heading {
    font-size: 3rem;
    line-height: 4rem;
    color: white;
    font-weight: 600;
    letter-spacing: -0.05em;
    margin-bottom: 2rem;
    font-family: var(--heading-fonts);
    text-align: center;
    margin-bottom: 3rem;
    margin-left: 30rem;
    width: 50%;
  }
  
  .about-us-hero-description{
    font-size: 1.5rem;
    line-height: 2rem;
    color: white;
    font-weight: 400;
    letter-spacing: 0.25px;
    font-family: var(--paragraph-fonts);
    text-align: center;
    width: 60%;
    margin-bottom: 3rem;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .about-us-hero-heading {
      font-size: 2rem !important;
      line-height: 3rem !important;
      margin-bottom: 0rem !important;
      margin-left: 20rem !important;
      width: 50%;
    }
    .about-us-hero-icon {
      width: 30% !important;
      top: 25% !important;
      left: 15% !important;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .about-us-hero-heading {
      font-size: 1.5rem !important;
      line-height: 2rem !important;
      margin-bottom: 0rem !important;
      margin-left: 0rem !important;
      width: 80% !important;
    }
    .about-us-hero-icon {
      display: none !important;
    }
  }