.cookie-hero-container {
  width: 100%;
  height: 20rem;
  padding: 5rem;
  background-color: var(--green-color-accent-dark);
}

.cookie-hero-content-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding-top: 5rem;
}

.cookie-hero-heading {
  color: white;
  width: 70%;
  text-align: center;
}


@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .cookie-hero-heading {
    font-size: 2rem !important;
    line-height: 3rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .cookie-hero-container {
    padding: 2rem !important;
  }
  .cookie-hero-heading {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    width: 100% !important;
  }
}