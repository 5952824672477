.demo-container {
  width: 100%;
  height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo-card {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    to right,
    var(--green-color-accent-dark),
    var(--green-color-primary-light)
  );
  border-radius: 30px;
}

.demo-card-heading {
  color: white;
  text-align: center;
}

.demo-card-description {
  color: white;
  text-align: center;
  margin-top: -2rem;
}

.demo-card-button {
  background: white;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-family: var(--paragraph-fonts);
  font-weight: 500;
  font-size: 1rem;
  color: var(--green-color-accent-dark);
  border: none;
}

.demo-card-button:hover {
  background: var(--green-color-accent-dark);
  border: 1px solid white;
  color: white;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .demo-card-heading {
    font-size: 2.5rem !important;
    line-height: 3.5rem !important;
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .demo-card-heading {
    font-size: 2rem !important;
    line-height: 3rem !important;
  }
  .demo-card-contact-image {
    width: 80px !important;
  }
}
