.features-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  margin-top: 5em;
  margin-bottom: 5em;
}

.shadow-text {
  color: var(--green-color-primary);
  font-weight: 700;
  text-shadow: 10px 3px 13px #c3fbed;
}

.features-title {
  color: black;
  margin-bottom: 2rem;
  text-align: center;
}

.cards-container {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  gap: 2rem;
}

.home-feature-card {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  border: 1px solid #e6e6e6;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0px rgba(0,0,0,0);
  border: 1px solid rgba(0, 0, 0, .125);
  transition: box-shadow 0.4s ease-in-out;
}

.home-feature-card:hover {
  transform: scale(1.03) 0.4s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.089) 0px 25px 20px -20px;
}

.card-icon-container {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-text-content {
  width: 100%;
  height: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-title {
  font-size: 1.5rem;
  line-height: 2rem;
  color: black;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 1rem;
  font-family: var(--heading-fonts);
  text-align: center;
}

.home-card-description {
  font-size: 1rem;
  line-height: 1.5rem;
  color: gray;
  font-weight: 400;
  letter-spacing: 0.25px;
  font-family: var(--paragraph-fonts);
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .features-title {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }

  .card-title {
    font-size: 1.2rem !important;
    line-height: 1.5rem !important;
  }
  
  .home-card-description {
    font-size: 0.9rem !important;
    line-height: 1.2rem !important;
  }

  .card-icon-container {
    height: 30% !important;
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .features-title {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  .card-title {
    font-size: 1.2rem !important;
    line-height: 1.5rem !important;
  }
  
  .home-card-description {
    font-size: 0.9rem !important;
    line-height: 1.2rem !important;
  }

  .card-icon-container {
    height: 30% !important;
  }
}
