.pricing-hero-container {
  width: 100%;
  height: 70vh;
  background-color: var(--green-color-accent-dark);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

.pricing-hero-icon {
  width: auto;
  height: 60vh;
  position: absolute;
  top: 10%;
  left: 10rem;
}

.pricing-hero-heading {
  color: white;
  margin-bottom: 2rem;
  text-align: center;
  margin-left: 15rem;
  width: 50%;
}

.pricing-hero-description {
  color: white;
  text-align: center;
  width: 60%;
  margin-bottom: 3rem;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .pricing-hero-heading {
    font-size: 2rem !important;
    line-height: 3rem !important;
    margin-bottom: 0rem !important;
    margin-left: 20rem;
    width: 50%;
  }
  .pricing-hero-icon {
    width: 40% !important;
    top: 10% !important;
    left: 7% !important;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-hero-heading {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    margin-bottom: 0rem !important;
    margin-left: 0rem !important;
    width: 80% !important;
  }
  .pricing-hero-icon {
    display: none !important;
  }
}