@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .pricing-card-modal-title2 {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    margin-left: 0rem !important;
  }

  .modal-content-heading1 {
    font-size: 0.9rem !important;
  }

  .pricing-modal-horizontal-line {
    border-top: 0.5px;
    border-color: var(--lightgray-colour);
    width: 8rem;
  }

  .local-create-steps-modal {
    display: none !important;
  }

  .local-create-steps-modal
    > div.ant-steps-item:not(:first-child).ant-steps-item-finish
    > div.ant-steps-item-container {
    display: none !important;
  }

  .local-create-steps-modal
    > div.ant-steps-item:not(:first-child).ant-steps-item-active
    > div.ant-steps-item-container {
    display: none !important;
  }

  .local-create-steps-modal
    > div.ant-steps-item:first-child.ant-steps-item-finish
    > div.ant-steps-item-container {
    display: none !important;
  }

  .local-create-steps-modal
    > div.ant-steps-item:first-child.ant-steps-item-active
    > div.ant-steps-item-container {
    display: none !important;
  }

  .local-create-steps-modal
    > div.ant-steps-item-wait
    > div.ant-steps-item-container {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-form-heading {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  .pricing-form-sub-container {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .local-create-steps-modal
    > div.ant-steps-item:not(:first-child).ant-steps-item-finish
    > div.ant-steps-item-container {
    display: none !important;
  }

  .local-create-steps-modal
    > div.ant-steps-item:not(:first-child).ant-steps-item-active
    > div.ant-steps-item-container {
    display: none !important;
  }

  .local-create-steps-modal
    > div.ant-steps-item:first-child.ant-steps-item-finish
    > div.ant-steps-item-container {
    display: none !important;
  }

  .local-create-steps-modal
    > div.ant-steps-item:first-child.ant-steps-item-active
    > div.ant-steps-item-container {
    display: none !important;
  }

  .local-create-steps-modal
    > div.ant-steps-item-wait
    > div.ant-steps-item-container {
    display: none !important;
  }

  .pricing-form-button {
    width: 7rem !important;
    height: 2.5rem !important;
  }
}

.pricing-form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.pricing-form-sub-container {
  width: 80%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  border-radius: 20px;
  border: 1px solid var(--lightgray-colour);
  box-shadow: var(--box-shadow);
}

.pricing-form-heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}

.pricing-card-modal-title2 {
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
  font-family: var(--paragrapg-fonts);
}

.modal-content-heading1 {
  margin-top: 1rem;
  font-family: var(--paragraph-fonts);
  font-size: 0.9rem;
  font-weight: 400;
}

.pricing-modal-horizontal-line {
  border-top: 0.5px;
  border-color: var(--lightgray-colour);
  width: 8rem;
}

.sf-logo-container {
  display: flex;
  justify-content: center;
}

.pricing-form-phone-input > input {
  width: 100% !important;
}

.pricing-custom-button {
  margin-top: 1rem;
  width: 10rem !important;
  height: 3rem !important;
  font-size: 1rem;
  font-weight: 500;
  font-family: var(--paragraph-fonts);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-colour);
  background-color: var(--green-color-primary);
  box-shadow: 0 0 0px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s ease-in-out;
}

.pricing-custom-button:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: var(--green-color-primary) !important;
  border: none !important;
  color: white !important;
}

.local-create-steps-modal
  > div.ant-steps-item-wait
  > div.ant-steps-item-container {
  clip-path: polygon(
    92% 0%,
    100% 50%,
    92% 100%,
    0% 100%,
    8% 50%,
    0% 0%
  ) !important;

  padding: 0.5rem 2rem !important;
  width: 100% !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gray !important;
}

.local-create-steps-modal
  > div.ant-steps-item-wait
  > div.ant-steps-item-container::after {
  content: "";
  position: absolute;
  clip-path: polygon(
    92% 0%,
    100% 50%,
    92% 100%,
    0% 100%,
    8% 50%,
    0% 0%
  ) !important;
  width: 99% !important;
  background-color: white !important;
  min-height: 4.3rem !important;
}

/* not first child finish and active class for steps */

.local-create-steps-modal
  > div.ant-steps-item:not(:first-child).ant-steps-item-finish
  > div.ant-steps-item-container {
  background-color: var(--green-color-primary-light) !important;
  /* border color of filled for remaining step. */
  clip-path: polygon(
    92% 0%,
    100% 50%,
    92% 100%,
    0% 100%,
    8% 50%,
    0% 0%
  ) !important;

  padding: 0.5rem 2rem !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.local-create-steps-modal
  > div.ant-steps-item:not(:first-child).ant-steps-item-active
  > div.ant-steps-item-container {
  clip-path: polygon(
    92% 0%,
    100% 50%,
    92% 100%,
    0% 100%,
    8% 50%,
    0% 0%
  ) !important;

  padding: 0.5rem 2rem !important;
  width: 100% !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green-color-primary-light) !important;
  /* border color of other remaining active step. */
}

.local-create-steps-modal
  > div.ant-steps-item:not(:first-child).ant-steps-item-active
  > div.ant-steps-item-container::after {
  content: "";
  position: absolute;
  clip-path: polygon(
    92% 0%,
    100% 50%,
    92% 100%,
    0% 100%,
    8% 50%,
    0% 0%
  ) !important;
  width: 99% !important;
  background-color: white !important;
  min-height: 4.3rem !important;
}

/* first child finish and active class for steps */

.local-create-steps-modal
  > div.ant-steps-item:first-child.ant-steps-item-finish
  > div.ant-steps-item-container {
  background-color: var(--green-color-primary-light) !important;
  /* border color of finished filled step. */
  clip-path: polygon(0% 0%, 92% 0%, 100% 50%, 92% 100%, 0% 100%) !important;

  padding: 0.5rem 2rem !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.local-create-steps-modal
  > div.ant-steps-item:first-child.ant-steps-item-active
  > div.ant-steps-item-container {
  clip-path: polygon(0% 0%, 92% 0%, 100% 50%, 92% 100%, 0% 100%) !important;

  padding: 0.5rem 2rem !important;
  width: 100% !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green-color-primary-light) !important;
  /* border color of first step. */
}

.local-create-steps-modal
  > div.ant-steps-item:first-child.ant-steps-item-active
  > div.ant-steps-item-container::after {
  content: "";
  position: absolute;
  clip-path: polygon(0% 0%, 92% 0%, 100% 50%, 92% 100%, 0% 100%) !important;

  width: 99% !important;
  background-color: white !important;
  min-height: 4.3rem !important;
}

.local-create-steps-modal
  > div.ant-steps-item-finish
  > div.ant-steps-item-container
  > div.ant-steps-item-icon {
  display: none !important;
}

.local-create-steps-modal
  > div.ant-steps-item-active
  > div.ant-steps-item-container
  > div.ant-steps-item-icon {
  display: none !important;
}

.local-create-steps-modal
  > div.ant-steps-item-active
  > div.ant-steps-item-container
  > div.ant-steps-item-icon {
  display: none !important;
}

.local-create-steps-modal
  > div.ant-steps-item-active
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-title {
  color: var(--green-color-primary-light) !important;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  font-family: var(--heading-fonts) !important;
  /* border color of active step title. */
  z-index: 1 !important;
}

.local-create-steps-modal
  > div.ant-steps-item-active
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-title::after {
  display: none !important;
}

.local-create-steps-modal
  > div.ant-steps-item-wait
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-title::after {
  display: none !important;
}

.local-create-steps-modal
  > div.ant-steps-item-finish
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-title::after {
  display: none !important;
}

.local-create-steps-modal
  > div.ant-steps-item-active
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-description {
  color: var(--green-color-primary-light) !important;
  font-size: 0.9rem !important;
  font-weight: 300 !important;
  font-family: var(--paragraph-fonts) !important;
  /* border color of active step description. */
  z-index: 1 !important;
  position: relative;
}

.local-create-steps-modal
  > div.ant-steps-item-finish
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-title {
  color: white !important;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  font-family: var(--heading-fonts) !important;
  /* border color of fininshed step title. */
}

.local-create-steps-modal
  > div.ant-steps-item-finish
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-description {
  color: white !important;
  font-size: 0.9rem !important;
  font-weight: 300 !important;
  font-family: var(--paragraph-fonts) !important;
  /* border color of fininshed step desc. */
}

.local-create-steps-modal
  > div.ant-steps-item-wait
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-title {
  color: #384250 !important;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  font-family: var(--heading-fonts) !important;
  /* border color of in-active step title. */
  z-index: 1 !important;
}

.local-create-steps-modal
  > div.ant-steps-item-wait
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-description {
  color: #6c737f !important;
  font-size: 0.9rem !important;
  font-weight: 300 !important;
  font-family: var(--paragraph-fonts) !important;
  /* border color of inactive step desc. */
  z-index: 1 !important;
  position: relative;
}

.local-create-steps-modal {
  margin-bottom: 2rem !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

.local-create-steps-inner-container {
  flex: 1 !important;
}
