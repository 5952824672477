.Leadership-team-section {
    margin-top: 5em;
    background: var( --green-color-secondary-light-alpha);
    width: 100%;
    min-height: 40em;
    padding: 1em;
  }

  .developer-team-section {
    margin-top: 5em;
    width: 100%;
    min-height: 40em;
    padding: 1em;
  }

  .developer-team-section h1 {
    font-family: var(--storefront-heading-font-family);
    font-size: 22px;
    font-weight: 800;
    color: var(--black-color);
    text-align: center;
    text-transform: capitalize;
    margin-top: 2em;
  }

  .Leadership-team-section h1 {
    font-family: var(--storefront-heading-font-family);
    font-size: 22px;
    font-weight: 800;
    color: var(--black-color);
    text-align: center;
    text-transform: capitalize;
    margin-top: 2em;
  }
  .Team-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin-top: 3em;
    flex-wrap: wrap;
  }
  .Team-card {
    position: relative;
  }
  .Team-card-img img {
    width: 300px;
    height: 350px;
    border-radius: 10px;
    overflow: hidden;
    object-fit: cover;
  }
  .Team-card-img img:hover {
    transition: 0.5s;
    box-shadow: var(--dashboard-common-orange-shadow);
  }
  .info-text-container {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 93%;
    background-color: var(--background-color);
    padding: 10px;
    box-sizing: border-box;
    min-height: 4em;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .Team-card-text p:first-child {
    font-family: var(--storefront-heading-font-family);
    font-size: 14px;
    font-weight: 600;
    color: var(--black-color);
    text-transform: capitalize;
    text-align: left;
    margin: 0;
  }
  .Team-card-text p:last-child {
    font-family: var(--storefront-heading-font-family);
    font-size: 12px;
    font-weight: 400;
    color: var(--black-color);
    text-transform: capitalize;
    text-align: left;
    margin: 0;
  }
  .linkedin-icon {
    font-size: 30px;
  }
  
  @media (max-width: 1500px) {
    .Info-tab-content p {
      width: 100% !important;
    }
    .Info-tab-content img {
      display: none !important;
    }
  }
  