.video-main-container {
  width: 100%;
  height: 40rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
}

.video-card-container {
  width: 80%;
  height: 100%;
  background: linear-gradient(
    to top right,
    #30928e,
    white,
    #1a4a45
  );
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
