.Ent-pg-icon {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 20px;
  background: linear-gradient(84.99deg, #449afa 0%, #1d63ff 100%);
  padding: 20px;
  box-shadow: #1e8fff57 0px 4px 16px, #1e8fff3e 0px 8px 32px;
}
.Dashboard-sidebar-icon {
  margin-right: 10px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .Ent-pg-icon {
    width: 0.7rem !important;
    height: 0.7rem !important;
    padding: 20px !important;
    border-radius: 20px !important;
  }
}
