.testimonial-container {
  width: 100%;
  height: 50rem;
  display: flex;
  flex-direction: row;
}

.card-container {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
}

.testimonial-card {
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 80%;
  max-width: 80%;
  padding: 2em 0;
  background: #fff;
  box-shadow: 0 0 6px 0 rgba(32, 32, 36, 0.12);
  transition: all 0.35s ease;
  z-index: 10;
}

.testimonial-card::before,
.testimonial-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--green-color-accent-dark);
  height: 4px;
}

.testimonial-card::before {
  width: 0;
  opacity: 0;
  transition: opacity 0 ease, width 0 ease;
  transition-delay: 0.5s;
}

.testimonial-card::after {
  width: 100%;
  background: white;
  transition: width 0.5s ease;
}

.testimonial-card .testimonial-content {
  width: 100%;
  max-width: 80%;
}

.testimonial-card .testimonial-logo {
  margin: 0 0 1em;
  width: 10.625em;
  color: black;
  transition: all 0.35s ease;
}

.testimonial-card .testimonial-h6 {
  color: #999;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 2px;
  font-family: var(--paragraph-fonts);
}

.testimonial-card .testimonial-hover_content {
  overflow: hidden;
  max-height: 0;
  transform: translateY(1em);
  transition: all 0.55s ease;
}

.testimonial-card .testimonial-hover_content p {
  margin: 1.5em 0 0;
  color: #6e6e70;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-family: var(--paragraph-fonts);
  font-weight: 400;
  letter-spacing: 0.25px;
}

.testimonial-card:hover {
  width: 80%;
  box-shadow: 0 10px 20px 0 rgba(32, 32, 36, 0.12);
}

.testimonial-card:hover::before {
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s ease, width 0.5s ease;
  transition-delay: 0;
}

.testimonial-card:hover::after {
  width: 0;
  opacity: 0;
  transition: width 0 ease;
}

.testimonial-card:hover .testimonial-logo {
  margin-bottom: 0.5em;
}

.testimonial-card:hover .testimonial-hover_content {
  max-height: 20rem;
  transform: none;
}

.image-container {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.colored-container {
  width: 40rem;
  height: 80%;
  background-color: var(--green-color-secondary);
  border-radius: 50px;
  display: flex;
  align-items: flex-start;
  position: absolute;
  left: -328px;
  top: 5rem;
}

.colored-rotated-text {
  transform: rotate(90deg);
  font-size: 7rem !important;
  line-height: 3rem !important;
  font-weight: 900 !important;
  letter-spacing: 0.05em !important;
  color: var(--green-color-accent-dark);
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .testimonial-card .testimonial-logo {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }
  .colored-container {
    width: 32rem !important;
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .testimonial-card .testimonial-logo {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
  .colored-container {
    display: none !important;
  }
  .colored-rotated-text {
    display: none !important;
  }
  .image-container {
   display: none !important;
  }
  .card-container {
    width: 100% !important;
    justify-content: center !important;
  }
  .testimonial-card {
    width: 80% !important;
  }
  .testimonial-container {
    height: 40rem !important;
  }
}