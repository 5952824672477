.home-features-carousal
  .ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab {
  border: none !important;
  padding: 1em;
  text-align: center;
  background: transparent;
  font-family: var(--storefront-heading-font-family);
  font-weight: 600;
  letter-spacing: 0.2px;
  font-size: 1.2rem;
}
.home-features-carousal
  .ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: var(--black-color) !important;
  background-color: var(--green-color-secondary-light-alpha);
  border-radius: 10px;
  font-family: var(--heading-fonts);
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.2px;
  font-size: 1.2rem;
  width: 12rem;
  height: 3rem;
  border: 1px solid var(--green-color-primary-light);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.home-features-carousal .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}
.home-features-carousal
  .ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab:hover {
  color: var(--green-color-primary-light) !important;
}
.home-features-carousal {
  position: relative;
  margin-top: 5rem;
}