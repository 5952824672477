.contactus-strong-text {
    display: flex;
    justify-content: center;
  }
  
  .contact-us-form-button-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .contact-us-phone-input > input {
    width: 100% !important;
  }

  .contact-us-form-button {
    width: 100%;
    font-family: var(--storefront-heading-font-family);
    font-size: 14px;
    font-weight: 400;
  }
  .contact-us-form-card-div {
    display: flex;
    align-items: center;
  }
  .contact-us-form-card {
    width: 100%;
    height: 100%;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .contact-custom-button {
    margin-top: 1rem;
    width: 10rem !important;
    height: 3rem !important;
    font-size: 1rem;
    font-weight: 500;
    font-family: var(--paragraph-fonts);
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    color: var(--white-colour);
    background-color: var(--green-color-primary);
    box-shadow: 0 0 0px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .contact-custom-button:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: var(--green-color-primary) !important;
    border: none !important;
    color: white !important;
  }

  @media only screen and (max-width: 767px) {
    .contact-us-form-card {
      padding: 1rem !important;
    }
  }