.slick-page-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
     
}
ol li span{
    font-size: 18px;
    font-weight: 700;
    color: #50b0a2;
    line-height: 25px;
}
li::marker {
    color: #50b0a2;
    font-size: 18px;
    font-weight: 700;
}
.point-disc {
    font-size: 1rem;
 
    margin-bottom: 0.5rem; 
}
.slack-integration-heading{
    font-size: 1.75rem;
    font-weight: 700;
    color: #50b0a2;
}
.slack-integration--bottom-disc{
    font-size: 1rem;
    font-weight: 500;
     
}
.slack-integration--bottom-heading{
    font-size: 1.3rem;
    font-weight: 600;
    margin: 10px;
}