.terms-conditions-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
}

.terms-conditions-container h1 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #011918;
}

.terms-conditions-container h2 {
    font-size: 1.75rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: #50b0a2;
}

.terms-conditions-container p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
}

.terms-conditions-container ul {
    margin-left: 20px;
    list-style-type: disc;
}

.terms-conditions-container a {
    color: #50b0a2;
    text-decoration: none;
}

.terms-conditions-container a:hover {
    text-decoration: underline;
}