.contact-us-hero-container {
    width: 100%;
    height: 60rem;
    background-color: #1a4a45;
    padding: 0rem 12rem;
    padding-top: 10rem;
    padding-bottom: 5rem;
  }

  .contact-us-hero-logo-section{
    background-color: white;
    border-radius: 10px;
    padding: 1rem;
  }
  
  .contact-form-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contact-us-hero-content-ccontainer{
    display: flex;
    justify-content: right;
  }

  .contact-us-hero-bold-text-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0rem;
  }
  .contact-us-hero-heading{
    display: flex;
    align-items: left;
  }

  .contact-us-hero-bold-text{
    color: black;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.25px;
    font-family: var(--heading-fonts);
    text-align: center;
    margin-top: 1rem;
  }

  .contact-us-hero-logo-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contact-us-hero-description{
    font-size: 1rem;
    line-height: 1.5rem;
    color: gray;
    font-weight: 400;
    letter-spacing: 0.25px;
    font-family: var(--paragraph-fonts);
  }

  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .contact-us-hero-container {
      padding: 0rem 5rem !important;
      padding-top: 10rem !important;
      padding-bottom: 5rem !important;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .contact-us-hero-container {
      padding: 0rem 2rem !important;
      padding-top: 7rem !important;
      padding-bottom: 5rem !important;
    }
  }