.Info-main-container {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 5em;
}

.Info-Heading > h1 {
  font-family: var(--heading-fonts);
  font-size: 22px;
  font-weight: 800;
  color: var(--green-color-accent-dark);
  text-transform: capitalize;
}
.Info-Heading p {
  font-family: var(--paragraph-fonts);
  font-size: 20px;
  font-weight: 400;
  color: var(--black-color);
}

.Info-tab-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  margin-top: 3em;
  gap: 2em;
}
.Info-tabs {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  justify-content: space-around;
}

.Info-tabs button {
  font-family: var(--heading-fonts);
  font-size: 16px;
  font-weight: 700;
  color: var(--black-color);
  background-color: transparent;
  border: 0.5px solid #eeebeb;
  outline: none;
  width: 15em;
  padding: 1em;
  border-radius: 15px;
  height: 7em;
}
.Info-tabs button:hover {
  background-color: var(--green-color-secondary-light-alpha);
  transition: 0.5s ease-in-out;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.Info-tabs button.active {
  background: linear-gradient(
    to right,
    var(--green-color-accent-dark),
    var(--green-color-primary-light)
  );

  color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.Info-tab-content {
  font-family: var(--heading-fonts);
  font-size: 16px;
  font-weight: 700;
  color: var(--black-color);
  background-color: transparent;
  outline: none;
  width: 15em;
  padding: 1em;
  border-radius: 15px;
  min-height: 22.7em;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  min-width: 55%;
}
.Info-tab-content h1 {
  font-family: var(--heading-fonts);
  font-size: 22px;
  font-weight: 800;
  color: var(--black-color);
  text-transform: capitalize;
  text-align: start;
}
.Info-tab-content p {
  font-family: var(--paragraph-fonts);
  font-size: 18px;
  font-weight: 400;
  color: #343436a3;
  text-align: start;
  width: 80%;
}
.Info-tab-content div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
}
.Info-tab-content img {
  width: 300px;
  height: 300px;
}

.Info-tab-content section {
  margin-top: 2em;
  margin-left: 20px;
}
