.faq-outer-container {
  width: 100%;
  height: 53rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--green-color-secondary-light-alpha);
}

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 100%;
}

.faq-container-heading {
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
  font-style: 600;
  font-family: var(--heading-fonts);
  line-height: 3rem;
  text-align: center !important;
}

.pricing-faqs-heading-colored {
  color: var(--green-color-primary-light);
}

.faqs-subheading-row {
  margin-bottom: 2rem;
  justify-content: center;
}

.faq-container-subheading {
  padding-bottom: 4rem !important;
  font-size: 1.5rem;
  font-family: var(--paragraph-fonts);
  font-weight: 500;
  line-height: 2rem;
  text-align: center;
}

.faqs-collapse-style {
  box-shadow: 0.3rem 0.3rem 0.3rem 0rem rgba(0, 0, 0, 0.05);
  border-radius: 0 40px 0 40px;
}

.faq-collapse-panel-style {
  border: 0;
  border-radius: 10px !important;
  background: var(--white-colour);
  height: 5rem !important;
  font-size: 1.2rem !important;
  font-family: var(--paragraph-fonts) !important;
  line-height: 2rem !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.faq-collapse-panel-style {
  font-size: 1.2rem !important;
  font-family: var(--paragraph-fonts) !important;
  line-height: 2rem !important;
}

.faqs-collapse-style
  > .ant-collapse-item-active
  > .ant-collapse-content-active
  > .ant-collapse-content-box {
  height: 5rem !important;
  display: flex !important;
  align-items: center !important;
}

.faqs-collapse-style
  > .ant-collapse-item-active
  > .ant-collapse-content-active {
  height: 5rem !important;
}

.faqs-collapse-style > .ant-collapse-item-active {
  height: 10rem !important;
}

.faq-collapse-panel-style-div {
  height: 5rem;
  display: flex;
  align-items: center;
}

.faqs-answer-style {
  font-size: 1.2rem !important;
  font-family: var(--paragraph-fonts) !important;
  line-height: 2rem !important;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .faq-container-heading {
    font-size: 2rem !important;
    line-height: 3rem !important;
  }
  .faq-container-subheading {
    font-size: 1.2rem !important;
    line-height: 1.7rem !important;
    text-align: center !important;
  }
}

@media only screen and (max-width: 767px) {
  .faqs-collapse-style > .ant-collapse-item-active {
    height: 15rem !important;
  }
  .faq-collapse-panel-style-div {
    height: 7.5rem !important;
  }
  .faqs-collapse-style
    > .ant-collapse-item-active
    > .ant-collapse-content-active {
    height: 7.5rem !important;
  }
  .faqs-collapse-style
    > .ant-collapse-item-active
    > .ant-collapse-content-active
    > .ant-collapse-content-box {
    height: 7.5rem !important;
  }
  .faq-collapse-panel-style {
    font-size: 1rem !important;
    line-height: 1.2rem !important;
  }
  .faqs-answer-style {
    font-size: 1rem !important;
    line-height: 1.2rem !important;
  }
  .faq-container-heading {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
  .faq-container-subheading {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    text-align: center !important;
  }
}
